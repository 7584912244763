<template>
  <div class="container">
    <div id="demoContent">
      <el-carousel :interval="3000" arrow="always">
        <el-carousel-item v-for="(item,index) in imageList" :key="index">
          <img class="carouselImg" :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="title tem02">
      <h4>会议简介</h4>
      <!-- <a class="more" href="/msite/channel/cn/6908.html">详情</a> -->
    </div>
    <div class="txt txt1 tem02">
      <p style="text-align: left; text-indent: 2rem; color: rgb(33, 37, 41)">
        中华医学会北京分会放射学分会2021年学术大会、北京医师协会放射医师分会2021年学术大会、京津冀医学影像高峰论坛定于2021年7月23-25日在北京国际饭店举行。7月23日为线上直播会议，7月24-25日为线下会议、线上直播。本次会议由中华医学会北京分会、北京医学会放射学分会主办；北京医师协会放射医师分会、天津市医学会放射学分会、河北省医学会放射学分会协办；北京医院和北京协和医院承办。
      </p>
      <p style="text-align: left; text-indent: 2rem; color: rgb(33, 37, 41)">
        本届年会的主题是“引领、共享、发展”，立足于广泛性、普遍性，着眼于科学性、前瞻性。分会18个学组和青年委员将对全身各系统的影像诊断和介入治疗展开深入探讨，同时邀请全国专家前来授课、指导，把学术活动推向新的高潮。
      </p>
    </div>
    <div class="title tem02">
      <h4>会议动态</h4>
      <!-- <a class="more" href="/msite/news/lists/cn/1469.html">更多</a> -->
    </div>
    <ul class="news-list tem02">
      <li>
        <a href="http://101.200.177.46:8092/static/handbook.pdf" target="_blank"
          >会议手册pdf版下载</a
        >
      </li>
      <li>
        <a
          href="http://4424.meeting.so/msite/news/show/cn/4887.html"
          target="_blank"
          >视频录制说明ppt版下载</a
        >
      </li>
      <li>
        <a
          href="http://4424.meeting.so/msite/news/show/cn/4866.html"
          target="_blank"
          >北京医学会放射学分会年会2021学术大会参会通知下载（第一轮通知）</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MeetingInfo",
  data() {
    return {
      imageList: [
        require("@/assets/mobile/bg_mobile.jpg"),
        require("@/assets/mobile/MR.jpg"),
        require("@/assets/mobile/banner_GE.jpg"),
      ],
    };
  },
};
</script>

<style scoped>

.carouselImg {
  width: 100%;
}

.container .title {
  border-bottom: 1px solid #c10000;
  padding: 18px;
  margin-top: 22px;
  position: relative;
}

.container .title > h4 {
  font-weight: bold;
  font-size: 20px;
  color: #444444;
  line-height: 1.4em;
  padding-left: 24px;
  position: relative;
  margin: 0;
}
.container .title > h4:after {
  content: "";
  position: absolute;
  left: -5px;
  top: 5px;
  width: 20px;
  height: 20px;
  background-color: #c10000;
}
.container .title > a {
  font-size: 16px;
  color: #000;
  font-weight: bold;
  position: absolute;
  right: 30px;
  top: 20px;
}
.container .txt {
  font-size: 14px;
  color: #444444;
  line-height: 25px;
  padding: 25px 30px;
  font-weight: normal;
}
.container .news-list {
  padding: 25px 30px;
}
.container .news-list > li:nth-child(1),
.container .news-list > li:nth-child(2),
.container .news-list > li:nth-child(3) {
  color: #e53e24;
}
.container .news-list > li {
  color: #797979;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.container .news-list > li:nth-child(1) a,
.container .news-list > li:nth-child(2) a,
.container .news-list > li:nth-child(3) a {
  color: #e53e24;
}
.container .news-list > li a {
  font-size: 16px;
  color: #797979;
  line-height: 1.8em;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
}
</style>